import * as Styled from "./TabPaymentMethod.styles";

import { useCallback, useEffect } from "react";

import { Typography } from "@mui/material";
import getImageFromPayment from "@utils/getImageFromPayment";
import useApplePay from "@hooks/useApplePay";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

interface TabPaymentMethodProps {
  hideTitle?: boolean;
}

function TabPaymentMethod({ hideTitle }: TabPaymentMethodProps) {
  const { supportsApplePay } = useApplePay();

  const { placeCode, selectedPaymentMethod, refreshPaymentMethods } = useTab();

  const navigate = useNavigate();

  const handleChangePaymentMethod = useCallback(() => {
    navigate(`/venue/${placeCode}/select`);
  }, [navigate, placeCode]);

  useEffect(() => {
    refreshPaymentMethods();
  }, [refreshPaymentMethods]);

  if (selectedPaymentMethod?.label === "Apple Pay" && !supportsApplePay) {
    return (
      <Styled.MainContainer
        direction="column"
        hideTitle={hideTitle}
        className="tab-paymentMethod"
      >
        {!hideTitle && (
          <Typography id="payment-method" className="tips-title" variant="h2">
            <a href="#payment-method">Your Payment Method</a>
          </Typography>
        )}
        <Styled.Container className="not-supported-txt">
          <Styled.PaymentMethodIcon
            width={50}
            src={getImageFromPayment({
              type: selectedPaymentMethod?.type,
              image: selectedPaymentMethod?.image,
            })}
            alt="Selected Payment Method icon"
          />
          <span>ApplePay not supported!</span>
          <Styled.ChangeButton
            className="overwrite solid-btn"
            text="Change"
            size="small"
            onClick={handleChangePaymentMethod}
          />
        </Styled.Container>
      </Styled.MainContainer>
    );
  }

  return (
    <Styled.MainContainer
      direction="column"
      hideTitle={hideTitle}
      className="tab-paymentMethod"
    >
      {!hideTitle && (
        <Typography id="payment-method" className="tips-title" variant="h2">
          <a href="#payment-method">Your Payment Method</a>
        </Typography>
      )}
      <Styled.Container>
        <Styled.PaymentMethodIcon
          width={50}
          src={getImageFromPayment({
            type: selectedPaymentMethod?.type,
            image: selectedPaymentMethod?.image,
          })}
          alt="Selected Payment Method icon"
        />
        <span>{selectedPaymentMethod?.label}</span>
        <Styled.ChangeButton
          className="overwrite solid-btn"
          text="Change"
          size="small"
          onClick={handleChangePaymentMethod}
        />
      </Styled.Container>
    </Styled.MainContainer>
  );
}

export default TabPaymentMethod;
