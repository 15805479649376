import { useCallback, useEffect } from "react";

import Button from "@component/Button/Button";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import { colors } from "@theme/Themes";
import styled from "styled-components";
import useApplePay from "@hooks/useApplePay";

interface ApplePayTokenProps {
  onSuccess: (nonce: string, paymentType: string) => void;
  onError: (error: React.ReactNode) => void;
  setError: React.Dispatch<React.SetStateAction<ErrorType | undefined>>;
}

const ApplePayBtn = styled.div`
  &.apple-pay-button-with-text {
    border-radius: 6px;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: pay;
    width: 100%;
    height: 44px;
    -apple-pay-button-style: ${colors.applePayBtnStyle};
  }
  &.apple-pay-button-with-text > * {
    display: none;
  }
`;

function ApplePayToken({ onSuccess, onError, setError }: ApplePayTokenProps) {
  const { isLoading, getApplePayToken, error, success } = useApplePay();

  const handleApplePayToken = useCallback(() => {
    void getApplePayToken();
  }, [getApplePayToken]);

  useEffect(() => {
    if (error) {
      setError(error.type);

      onError(
        <>
          <strong>Error:</strong> {error.errorMessage}
        </>
      );
    }
  }, [error, onError, setError]);

  useEffect(() => {
    if (success) {
      onSuccess(success.nonce, success.paymentType);
    }
  }, [onSuccess, success]);

  if (isLoading) {
    return (
      <Button
        ariaLabel="Connecting Apple Pay"
        className="expressCheckoutBtn"
        isLoading={isLoading}
        size="medium"
      />
    );
  }

  return (
    <ApplePayBtn
      className="apple-pay-button-with-text"
      onClick={handleApplePayToken}
    />
  );
}

export default ApplePayToken;
