import { useCallback, useEffect, useState } from "react";

import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import { applePayLabels } from "@vendor/utils/constants";
import braintree from "braintree-web";
import getTabUserAccount from "@utils/getTabUserAccount";
import trackGaEvent from "@utils/trackGaEvent";
import updateTabUserAccount from "@utils/updateTabUserAccount";
import { usePlace } from "@context/PlaceContext";
import { useTab } from "@context/TabContext";

interface SuccessProps {
  nonce: string;
  paymentType: string;
}

interface ErrorProps {
  type: ErrorType | undefined;
  errorMessage: string | undefined;
}

interface UseApplePayProps {
  error: ErrorProps | undefined;
  getApplePayToken: () => Promise<void>;
  isLoading: boolean;
  success: SuccessProps | undefined;
  supportsApplePay: boolean;
}

const useApplePay = (): UseApplePayProps => {
  const [supportsApplePay, setSupportsApplePay] = useState<boolean>(false);
  const [applePayInstance, setApplePayInstance] =
    useState<braintree.ApplePay>();
  const [hasUsername, setHasUsername] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorProps | undefined>();
  const [success, setSuccess] = useState<SuccessProps | undefined>();

  const { place } = usePlace();
  const placeCode = place?.code;

  const { tabInstance, tabTotal } = useTab();

  useEffect(() => {
    if (
      window.ApplePaySession &&
      ApplePaySession.supportsVersion(3) &&
      ApplePaySession.canMakePayments()
    ) {
      setSupportsApplePay(true);
    }
  }, [supportsApplePay]);

  const getUserName = useCallback(async () => {
    const name = await getTabUserAccount();

    setHasUsername(!!name?.first && !!name.last);
  }, []);

  useEffect(() => {
    void getUserName();
  }, [getUserName]);

  const setTokenizeErrorState = useCallback(() => {}, []);

  const createApplePay = useCallback(async () => {
    if (tabInstance) {
      await braintree.applePay
        .create({ client: tabInstance })
        .then((payInstance) => {
          setApplePayInstance(payInstance);
        })
        .catch((error) => {
          console.error(
            "ApplePay > Error Creating Braintree Payment instance",
            error
          );
        });
    }
  }, [tabInstance]);

  useEffect(() => {
    if (tabInstance) {
      createApplePay().catch((error) => console.error(error));
    }
  }, [createApplePay, tabInstance]);

  const getApplePayToken = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    if (applePayInstance) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const braintreePaymentRequest = applePayInstance?.createPaymentRequest({
        total: {
          label: applePayLabels.paymentSheet.totalLabel,
          amount: tabTotal ?? "0.00",
          type: tabTotal ? "final" : "pending",
        },
        requiredBillingContactFields: ["name", "postalAddress"],
      } as ApplePayJS.ApplePayPaymentRequest);

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const session = new ApplePaySession(3, {
        ...braintreePaymentRequest,
        total: {
          label: applePayLabels.paymentSheet.totalLabel,
          amount: tabTotal ?? "0.00",
          type: tabTotal ? "final" : "pending",
        },
      } as ApplePayJS.ApplePayPaymentRequest);

      session.oncancel = (event: ApplePayJS.Event): void => {
        event.cancelBubble = true;
        setIsLoading(false);

        trackGaEvent("ApplePay onCancel by user", {
          description: `PlaceCode: ${placeCode}`,
        });
      };

      session.onpaymentauthorized = (
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent
      ) => {
        if (event.payment) {
          const _firstName = event.payment.billingContact?.givenName;
          const _lastName = event.payment.billingContact?.familyName;

          const updateName = async (_firstName: string, _lastName: string) => {
            await updateTabUserAccount(_firstName, _lastName);
          };

          applePayInstance
            ?.tokenize({ token: event.payment.token })
            .then((payload) => {
              setSuccess({
                nonce: payload.nonce,
                paymentType: "APPLE_PAY",
              });

              if (!hasUsername && _firstName && _lastName) {
                void updateName(_firstName, _lastName);
              }

              session.completePayment(ApplePaySession.STATUS_SUCCESS);
            })
            .catch((error) => {
              setError({
                type: "tokenizeError",
                errorMessage:
                  "There was an error processing Apple Pay. Please try again or contact your server.",
              });
              console.error("ApplePay > onpaymentauthorized >", error);
              setIsLoading(false);

              trackGaEvent("Error: ApplePay tokenize", {
                description: `PlaceCode: ${placeCode}`,
              });

              session.completePayment(ApplePaySession.STATUS_FAILURE);
            });
        }
      };

      session.onvalidatemerchant = (
        event: ApplePayJS.ApplePayValidateMerchantEvent
      ) => {
        if (event.validationURL) {
          applePayInstance
            ?.performValidation({
              validationURL: event.validationURL,
              displayName: applePayLabels.validation.displayName,
            })
            .then((merchantSession) => {
              session.completeMerchantValidation(merchantSession);
            })
            .catch((error) => {
              setError({
                type: "tokenizeError",
                errorMessage:
                  "There was an error processing Apple Pay. Please try again or contact your server.",
              });

              console.error("ApplePay > Error Validating Merchant", error);
              setIsLoading(false);

              trackGaEvent("Error: Validating ApplePay Merchant", {
                description: `PlaceCode: ${placeCode}`,
              });

              try {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
              } catch (error) {
                console.error(
                  "ApplePay > Unable to set failure status for session",
                  session,
                  error
                );
                setTokenizeErrorState();
              }

              try {
                session.abort();
              } catch (error) {
                console.error(
                  "ApplePay > Unable to abort session",
                  session,
                  error
                );
              }
            });
        } else {
          console.error("ApplePay > Missing Merchant Validation URL");
          setIsLoading(false);
          setError({
            type: "tokenizeError",
            errorMessage:
              "There was an error processing Apple Pay. Please try again or contact your server.",
          });

          trackGaEvent("Error: Missing Apple Pay Merchant Validation URL", {
            description: `PlaceCode: ${placeCode}`,
          });
        }
      };

      session.begin();
    }

    trackGaEvent("Clicked ApplePay Button", {
      description: `PlaceCode: ${placeCode}`,
    });
  }, [
    applePayInstance,
    hasUsername,
    placeCode,
    setTokenizeErrorState,
    tabTotal,
  ]);

  return {
    error,
    getApplePayToken,
    isLoading,
    success,
    supportsApplePay,
  };
};

export default useApplePay;
