import * as Styled from "./ExpressCheckout.styles";

import React, { useCallback, useState } from "react";

import ApplePayToken from "./ApplePayToken";
import { Client } from "braintree-web";
import Error from "@component/Error/Error";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import GooglePayToken from "./GooglePayToken";
import useApplePay from "@hooks/useApplePay";

interface ExpressCheckoutWebTabProps {
  clientInstance: Client | undefined;
  onAddPaymentMethod?: (nonce: string, paymentType: string) => void;
}

function ExpressCheckoutWebTab({
  clientInstance,
  onAddPaymentMethod,
}: ExpressCheckoutWebTabProps): React.ReactElement {
  const [error, setError] = useState<ErrorType | undefined>();
  const [errorMessage, setErrorMessage] = useState<
    React.ReactNode | undefined
  >();

  const { supportsApplePay } = useApplePay();

  const handleError = useCallback((errorNode: React.ReactNode) => {
    setErrorMessage(errorNode);
  }, []);

  return (
    <Styled.Container isWebTab={true}>
      {error && errorMessage && <Error>{errorMessage}</Error>}

      {supportsApplePay && onAddPaymentMethod && (
        <ApplePayToken
          onSuccess={onAddPaymentMethod}
          onError={handleError}
          setError={setError}
        />
      )}

      {onAddPaymentMethod && (
        <GooglePayToken
          clientInstance={clientInstance}
          onSuccess={onAddPaymentMethod}
          onError={handleError}
          setError={setError}
        />
      )}
    </Styled.Container>
  );
}

export default ExpressCheckoutWebTab;
